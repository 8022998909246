import React, {Fragment, useState} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import {Accordion, AccordionDetails, AccordionSummary, Box, IconButton, List, Radio, RadioGroup, FormControl, FormControlLabel, FormLabel} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { setModalOpen, setModalRestaurant } from "../../redux/features/infoModal/infoModalSlice";

export const MissingInfo = ({open, handleClose}) => {
    const [filter, setFilter] = useState(''); // State for selected filter
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const dispatch = useDispatch();

    const restaurants = useSelector(
        (state) => state.restaurantsReducer.restaurants
    );

    const handleDialogClose = () => {
        handleClose();
    };

    const handleItemClick = (e, restaurant) => {
        e.stopPropagation();
        dispatch(setModalRestaurant(restaurant));
        dispatch(setModalOpen(true));
    };

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    // Sorting restaurants alphabetically by name
    const sortedRestaurants = [...restaurants].sort((a, b) =>
        a.name.localeCompare(b.name)
    );

    const getMissingInfo = (restaurant) => {
        let missingInfo = [];
        console.log(restaurant);
        // Check if fields are missing or empty
        if (!restaurant.placeId || restaurant.placeId.trim().length === 0) {
            missingInfo.push('placeId');
        }
        if (!restaurant.reels || restaurant.reels.length === 0) {
            missingInfo.push('reels');
        }
        if (!restaurant.menu_status || restaurant.menu_status.trim().length === 0) {
            missingInfo.push('menu_status');
        }
        if (!restaurant.tags || restaurant.tags.length === 0) {
            missingInfo.push('tags');
        }

        // Apply filter to only display restaurants missing the selected field
        if (filter && !missingInfo.includes(filter)) {
            return null; // Skip this restaurant if it doesn't match the selected filter
        }

        if (missingInfo.length === 0) {
            return null; // No missing info
        }

        return (
            <Accordion key={restaurant.name}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Button sx={{color: 'blue'}} onClick={(e) => handleItemClick(e, restaurant)}>
                        {restaurant.name}
                    </Button>
                </AccordionSummary>
                <AccordionDetails>
                    <ul>
                        {missingInfo.map((info) => (
                            <li key={info}>{info}</li>
                        ))}
                    </ul>
                </AccordionDetails>
            </Accordion>
        );
    };

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleDialogClose}
                fullScreen={fullScreen}
                aria-labelledby="Restaurant-directory-title"
            >
                <DialogTitle id="Restaurant-directory-title">
                    Missing Information
                    <IconButton
                        style={{position: "absolute", top: 10, right: 10}}
                        onClick={handleClose}
                    >
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <FormControl component="fieldset" style={{ marginBottom: '16px' }}>
                        <FormLabel component="legend">Filter by Missing Field</FormLabel>
                        <RadioGroup
                            row
                            aria-label="missing-field-filter"
                            name="missing-field-filter"
                            value={filter}
                            onChange={handleFilterChange}
                        >
                            <FormControlLabel value="placeId" control={<Radio />} label="Place ID" />
                            <FormControlLabel value="reels" control={<Radio />} label="Reels" />
                            <FormControlLabel value="menu_status" control={<Radio />} label="Menu Status" />
                            <FormControlLabel value="tags" control={<Radio />} label="Tags" />
                            <FormControlLabel value="" control={<Radio />} label="All" />
                        </RadioGroup>
                    </FormControl>
                    <List>
                        {sortedRestaurants.map((restaurant, index) => (
                            <React.Fragment key={index}>
                                {getMissingInfo(restaurant)}
                            </React.Fragment>
                        ))}
                    </List>
                    <Box
                        sx={{display: "flex", justifyContent: "flex-end", marginTop: 2}}
                    >
                        <Button
                            onClick={handleDialogClose}
                            color="primary"
                            variant="outlined"
                        >
                            Close
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </Fragment>
    );
};
