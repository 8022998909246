import React from 'react';

const InstagramProfile = ({ profileTag }) => {
    const isMobileDevice = window.innerWidth < 600;
    const minHeight = isMobileDevice ? 600 : 850;

    const embedUrl = `https://www.instagram.com/${profileTag}/embed`;

    return (
        <div>
            <iframe
                title={embedUrl}
                src={embedUrl}
                width="100%"
                height={minHeight}
                frameBorder="0"
                scrolling="no"
            />
        </div>
    );
}

export default InstagramProfile;