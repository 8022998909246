import React, { Fragment, useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    useMediaQuery,
    Box,
    IconButton,
    List,
    ListItem,
    Typography,
    Radio,
    RadioGroup,
    FormControl,
    FormControlLabel
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import CloseIcon from "@mui/icons-material/Close";
import { collection, getDocs } from "firebase/firestore";
import { db, DB_PATHS } from "../../containers/Firebase/Firebase";
import PendingRestaurantsModal from "./PendingRestaurantsModal";
import { useTheme } from "@mui/material/styles";

export default function PendingRestaurants({ open, handleClose }) {
    const [showModal, setShowModal] = useState(false);
    const [pendingRestaurants, setPendingRestaurants] = useState([]);
    const [modalContent, setModalContent] = useState({});
    const [selectedCity, setSelectedCity] = useState("Calgary");  // Default to "Calgary"
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    const handleDialogClose = () => {
        handleClose();
    };

    const handleItemClick = (restaurant) => {
        setShowModal(true);
        let content = {
            ...restaurant,
            address: [restaurant.address, restaurant.city, restaurant.province]
        };
        setModalContent(content);
    };

    const getPendingData = async () => {
        const snapshot = await getDocs(collection(db, DB_PATHS.REQUESTED));
        const restaurantData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));
        setPendingRestaurants(restaurantData);
    };

    useEffect(() => {
        getPendingData();
    }, []);

    const handleCityChange = (event) => {
        setSelectedCity(event.target.value);
    };

    const filteredRestaurants = pendingRestaurants.filter((restaurant) => {
        if (selectedCity === "Calgary") return restaurant.address.includes("Calgary");
        if (selectedCity === "Edmonton") return restaurant.address.includes("Edmonton");
        if (selectedCity === "Other") return !restaurant.address.includes("Calgary") && !restaurant.address.includes("Edmonton");
        return true;
    });

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleDialogClose}
                fullScreen={fullScreen}
                aria-labelledby="wishlist-dialog-title"
            >
                <DialogTitle id="wishlist-dialog-title">
                    Pending Restaurants ({filteredRestaurants.length})
                </DialogTitle>
                <DialogContent>
                    <IconButton
                        style={{ position: "absolute", top: 10, right: 10 }}
                        onClick={handleDialogClose}
                    >
                        <CloseIcon />
                    </IconButton>
                    <FormControl component="fieldset" sx={{ marginBottom: 2 }}>
                        <RadioGroup
                            row
                            value={selectedCity}
                            onChange={handleCityChange}
                            aria-label="city-filter"
                            name="city-filter"
                        >
                            <FormControlLabel value="Calgary" control={<Radio />} label="Calgary" />
                            <FormControlLabel value="Edmonton" control={<Radio />} label="Edmonton" />
                            <FormControlLabel value="Other" control={<Radio />} label="Other" />
                        </RadioGroup>
                    </FormControl>
                    <List>
                        {filteredRestaurants.map((restaurant, index) => (
                            <ListItem
                                key={index}
                                disablePadding
                                sx={{ "&:hover": { backgroundColor: "#f0f0f0" } }}
                            >
                                <Button
                                    fullWidth
                                    onClick={() => handleItemClick(restaurant)}
                                    startIcon={<StarIcon />}
                                    sx={{ justifyContent: "flex-start", textTransform: "none" }}
                                >
                                    {restaurant.name}
                                </Button>
                                <Typography>{restaurant.userName || restaurant.userId}</Typography>
                            </ListItem>
                        ))}
                    </List>
                    <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}>
                        <Button onClick={handleDialogClose} color="primary" variant="outlined">
                            Close
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
            {showModal && (
                <PendingRestaurantsModal
                    open={showModal}
                    handleClose={() => setShowModal(false)}
                    modalContent={modalContent}
                    getPendingData={getPendingData}
                />
            )}
        </Fragment>
    );
}
