import { Typography } from '@mui/material';
import React from 'react'
import { useSelector } from 'react-redux';

const AuthorizedRoute = ({ children }) => {
    const user = useSelector((state) => state.authReducer.user);
    const isAdmin = user?.isAdmin || false;

    return (
        <>
            {!isAdmin ? (
                <Typography>Unauthorized</Typography>
            ): (
                {...children}
            )}
        </>
    )
}

export default AuthorizedRoute
