export const all_tags = [
    'fried chicken',
    'pizza',
    'burgers',
    'shawarma',
    'sandwiches',
    'steak',
    'seafood',
    'poutine',
    'pho',
    'jerk chicken',
    'nashville hot chicken',
    'fine dining',
    'fast food',
    'food truck',
    'bangladeshi',
    'pakistani',
    'kebab',
    'dessert',
    'texas bbq',
    'tacos',
    'indian',
    'chinese',
    'sushi',
    'ramen',
    'korean',
    'thai',
    'vietnamese',
    'filipino',
    'mexican',
    'american',
    'italian',
    'greek',
    'mediterranean',
    'moroccan',
    'french',
    'ethiopian',
    'soup',
    'wrap',
    'chicken',
    'quesadilla',
    'milkshakes',
    'hot dogs',
    'pasta',
    'eggs',
    'rice',
    'biryani',
    'ribs',
    'meat pie',
    'bbq',
    'turkish',
    'lebanese',
    'quail',
    'breakfast',
    'brunch',
    'south indian',
    'dosa',
    'kabab',
    'yangnyeom',
    'vietnamese sub',
    'kabob'
]