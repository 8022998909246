import React, { useState } from 'react';
import {List, ListItemIcon, MenuItem, Pagination, Typography} from '@mui/material';
import RestaurantIcon from "@mui/icons-material/Restaurant"


const PaginatedList = ({ searchResults, itemsPerPage, onItemClick }) => {
    const [page, setPage] = useState(1);

    // Calculate the items to display for the current page
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedItems = searchResults.slice(startIndex, endIndex);

    // Update the page state when pagination changes
    const handlePageChange = (event, value) => setPage(value);

    if (searchResults.length === 0) return (
        <></>
    );

    return (
        <div>
            <List>
                {paginatedItems.map((searchResult, index) => (
                    <MenuItem key={index} onClick={() => onItemClick(searchResult)}>
                        <ListItemIcon>
                            <RestaurantIcon fontSize="small" sx={{color: '#00C2FF'}}/>
                        </ListItemIcon>
                        <Typography variant="body1">
                            {searchResult.name.length > 30
                                ? `${searchResult.name.slice(0, 30)}...`
                                : searchResult.name}
                        </Typography>
                        {searchResult.distance && ( // Check if distance exists
                            <Typography display='inline' style={{fontSize: "0.8rem", color: "#666", marginLeft: '0.3rem'}}>
                                ({searchResult.distance.toFixed(2)} km)
                            </Typography>
                        )}
                    </MenuItem>
                ))}
            </List>

            <Pagination
                count={Math.ceil(searchResults.length / itemsPerPage)}
                page={page}
                onChange={handlePageChange}
                color="primary"
                sx={{ marginTop: 2, display: 'flex', justifyContent: 'center' }}
            />
        </div>
    );
};

export default PaginatedList;
