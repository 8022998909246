import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, IconButton, Paper, TextField, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { addDoc, collection, deleteDoc, doc, getDocs } from 'firebase/firestore';
import { db, DB_PATHS } from '../../containers/Firebase/Firebase';
import { useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import { EditNoteComponent } from './EditNoteComponent';
import EditIcon from '@mui/icons-material/Edit';

const Notes = () => {
    const navigate = useNavigate();
    const [notes, setNotes] = useState([]);
    const [newNote, setNewNote] = useState("");
    const user = useSelector((state) => state.authReducer.user);
    const [editNoteOpen, setEditNoteOpen] = useState(false);
    const [editableNote, setEditableNote] = useState({});

    const handleBackToHome = () => {
        navigate('/');
    }

    const fetchNotes = () => {
        getDocs(collection(db, DB_PATHS.NOTES)).then((snapshot) => {
            const notesData = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            setNotes(notesData);
        });
    }

    useEffect(() => {
        fetchNotes();
    }, []);

    const handleAddNote = async () => {
        console.log('here');
        if (newNote === "") {
            alert("Note cannot be empty");
            return;
        }

        try {
          await addDoc(collection(db, DB_PATHS.NOTES), {
            text: newNote,
            createdBy: user.displayName
          });
          alert("Added new Note");
          setNewNote("");
          fetchNotes();
        } catch(err) {
            console.log('error adding note');
        }
    }

    const handleDeleteNote = async (id) => {
        if (window.confirm("Are you sure you want to delete this Note?")) {
            await deleteDoc(doc(db, DB_PATHS.NOTES, id));
            fetchNotes();
        }
    }

    const handleEdit = (note) => {
        console.log(note);
        setEditableNote(note);
        setEditNoteOpen(true);
    }

    return (
        <Grid container direction='column' gap={3}>
            <Button variant='contained' sx={{height: '3rem'}} onClick={handleBackToHome}>Back To Home</Button>
            <Grid item container direction='column' alignItems='center'>
                <Typography variant='h6'>Notes</Typography>
                <Grid item container justifyContent='center' alignItems='center'>
                    <TextField
                        value={newNote}
                        onChange={e => setNewNote(e.target.value)}
                        multiline sx={{width: '20rem'}}
                        variant='outlined'
                        type='text'
                        label='Note'
                    />
                    <Button onClick={handleAddNote} sx={{ height: '100%'}} variant='outlined'>Add Note</Button>
                </Grid>
                    <Box sx={{ padding: 2, margin: '0 auto' }}>
                        {notes.map((note) => (
                            <Paper
                                key={note.id}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    padding: 2,
                                    marginBottom: 2,
                                    borderRadius: 2,
                                    boxShadow: 3,
                                    backgroundColor: 'background.paper',
                                }}
                            >
                                <Typography variant="body1" sx={{ color: 'text.primary', minWidth: '10rem', fontWeight: 'medium' }}>
                                    {note.text}
                                </Typography>
                                <Box>
                                    <IconButton color='primary' onClick={() => handleEdit(note)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton color="error" onClick={() => handleDeleteNote(note.id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            </Paper>
                        ))}
                    </Box>
                    <EditNoteComponent note={editableNote} refetchNotes={fetchNotes} open={editNoteOpen} handleClose={() => setEditNoteOpen(false)} />
            </Grid>
        </Grid>
    )
}

export default Notes
