import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
  restaurantData: {},
  previewMode: false
};

const infoModalSlice = createSlice({
  name: "infoModal",
  initialState,
  reducers: {
    setModalOpen: (state, action) => {
      state.open = action.payload;
    },
    setPreviewMode: (state, action) => {
        state.previewMode = action.payload;
      },
    setModalRestaurant: (state, action) => {
      state.restaurantData = { ...action.payload };
    }
  },
});

export const { setModalOpen, setPreviewMode, setModalRestaurant } = infoModalSlice.actions;
export default infoModalSlice.reducer;
