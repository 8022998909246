import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import {persistor, store} from "./redux/store";
import {PersistGate} from "redux-persist/integration/react";
import App from "./components/App/App";
import {Provider} from "react-redux";
import {createBrowserRouter, RouterProvider,} from "react-router-dom";
import {PrivacyNotice} from "./components/PrivacyNotice/PrivacyNotice";
import {DeleteAccount} from "./components/DeleteAccount/DeleteAccount";
import {register} from "./containers/Firebase/serviceWorker";
import AuthorizedRoute from "./components/AuthorizedRoute/AuthorizedRoute";
import Notes from "./components/Notes/Notes";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
    },
    {
        path: "/privacy",
        element: <PrivacyNotice/>,
    },
    {
        path: "/delete",
        element: <DeleteAccount/>,
    },
    {
        path: '/restaurant/:id',
        element: <App/>
    },
    {
        path: '/notes',
        element: (
            <AuthorizedRoute>
                <Notes />
            </AuthorizedRoute>
        )
    },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <RouterProvider router={router}/>
        </PersistGate>
    </Provider>
);

register();
