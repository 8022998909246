import React, {useCallback, useEffect, useRef, useState} from "react";
import Box from "@mui/material/Box";
import {Divider, IconButton, InputAdornment, SwipeableDrawer, TextField} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {debounce} from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {filterRestaurants} from "./search-util";
import PaginatedList from "./PaginatedList";
import Typography from "@mui/material/Typography";
import TitleBarBelowImageList from "../Card/TitleBarBelowImageList";
import ClearIcon from "@mui/icons-material/Clear";
import { setModalOpen, setModalRestaurant } from "../../redux/features/infoModal/infoModalSlice";



const SearchMobile = ({isMenuOpen, setMenuOpen}) => {
    const allRestaurants = useSelector((state) => state.restaurantsReducer.restaurants);
    const nearbyRestaurants = useSelector((state) => state.restaurantsReducer.nearbyRestaurants);
    const restaurantsToUse = nearbyRestaurants.length > 0 ? nearbyRestaurants : allRestaurants;
    const searchRef = useRef(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const dispatch = useDispatch();
    const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);


    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedHandleSearch = useCallback(debounce((term) => {
        let searchString = term.toLowerCase().trim();
        if (searchString === "") {
            setSearchResults([]);
            return;
        }

        const filteredResults = filterRestaurants(restaurantsToUse, searchString);
        setSearchResults(filteredResults);
    }, 300), [allRestaurants]);

    useEffect(() => {
        if (isMenuOpen && searchRef.current) {
            searchRef.current.focus();
        }
    }, [isMenuOpen]);

    const handleSearch = (event) => {
        debouncedHandleSearch(event);
    };

    const onItemClick = (restaurant) => {
        dispatch(setModalRestaurant(restaurant));
        dispatch(setModalOpen(true));
    }

    const onStaffPickClick = (restaurantId) => {
        const restaurant = restaurantsToUse.find((restaurant) => restaurant.id === restaurantId);
        dispatch(setModalRestaurant(restaurant));
        dispatch(setModalOpen(true));
    }

    return (
        <>
            <SwipeableDrawer
                open={isMenuOpen}
                onOpen={() => setMenuOpen(true)}
                onClose={() => {
                    setMenuOpen(false);
                }}
                anchor={'right'}
                disableDiscovery={iOS} // iOS has a "swipe to go back" feature that interferes with the discovery feature, so discovery has to be disabled.
            >
                <Box sx={{ display: 'flex', alignItems: 'center', padding: 2 }}>
                    <ArrowBackIcon
                        sx={{ cursor: 'pointer', marginRight: 1, color: '#00C2FF' }}
                        onClick={() => setMenuOpen(false)}
                    />
                    <TextField
                        variant="outlined"
                        placeholder="Search..."
                        fullWidth
                        inputRef={searchRef}
                        sx={{
                            borderRadius: 1,
                        }}
                        value={searchTerm}
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                            handleSearch(e.target.value);
                        }}
                        InputProps={{
                            endAdornment: searchTerm && (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => {
                                            setSearchTerm('');
                                            handleSearch('');
                                        }}
                                        edge="end"
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                <Divider/>
                <PaginatedList searchResults={searchResults} itemsPerPage={7} onItemClick={onItemClick}/>
                {/*Below Section*/}
                <Box sx={{display: 'flex', flexDirection: 'column', width: '100vw', paddingTop: 5}}>
                    <Box sx={{mt: 'auto'}}> {/* Add padding for spacing */}
                        <Typography
                            variant="subtitle1"
                            color="#00C2FF"
                            fontWeight="bold"
                            gutterBottom
                            sx={{ paddingLeft: 2 }}
                        >
                            Staff Picks
                        </Typography>
                        <Divider sx={{ mb: 2 }} />
                    </Box>
                </Box>
                <TitleBarBelowImageList itemOnClick={onStaffPickClick}/>
            </SwipeableDrawer>
        </>
    )
};

export default SearchMobile;
