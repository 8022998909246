import React, {useState} from "react";
import { useSelector} from "react-redux";
import {Avatar, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, Grid, IconButton, Typography} from "@mui/material";
import EditPassword from "../EditPassword/EditPassword";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { toast } from "react-toastify";
import { collection, doc, getDocs, updateDoc } from "firebase/firestore";
import { db, DB_PATHS } from "../../containers/Firebase/Firebase";
import { DeleteUserModal } from "./DeleteUserModal";

const Profile = ({open, handleClose}) => {
    const [editPasswordOpen, setEditPasswordOpen] = useState(false);
    const [deleteUserOpen, setDeleteUserOpen] = useState(false);
    const user = useSelector((state) => state.authReducer.user);
    const fcmToken = useSelector((state) => state.authReducer.fcm_token);
    const [loading, setLoading] = useState(false);

    const updateData = async () => {
        if (window.confirm("Are you sure you want update all restaurants with place id information?")) {
            try {
                setLoading(true);

                let snapshot = await getDocs(collection(db, DB_PATHS.RESTAURANTS));
                const all_restaurants = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                for (const restaurant of all_restaurants) {
                    if (restaurant.placeId && restaurant.placeId.trim() !== '') {
                        console.log(`Updating data for restaurant: ${restaurant.id} (${restaurant.name}) with placeId: ${restaurant.placeId}`);
                        const placeId = restaurant.placeId;

                        const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
                        const url = `https://places.googleapis.com/v1/places/${placeId}?fields=id,photos,shortFormattedAddress,googleMapsUri,businessStatus,currentOpeningHours,nationalPhoneNumber,priceLevel,rating,userRatingCount,websiteUri&key=${apiKey}`;
                        try {
                            const response = await fetch(url);
                            if (!response.ok) {
                                throw new Error('Network response was not ok');
                            }
                            const placeData = await response.json();

                            let updatedData = {
                                googleMapsUri: placeData?.googleMapsUri || '',
                                currentOpeningHours: placeData?.currentOpeningHours?.weekdayDescriptions || [],
                                priceLevel: placeData?.priceLevel || "",
                                nationalPhoneNumber: placeData?.nationalPhoneNumber || "",
                                rating: placeData?.rating || "",
                                userRatingCount: placeData?.userRatingCount || "",
                                shortFormattedAddress: placeData?.shortFormattedAddress || "",
                                businessStatus: placeData?.businessStatus || "",
                                websiteUri: placeData?.websiteUri || "",
                            }

                            const restaurantDocRef = doc(db, 'restaurants', restaurant.id);
                            await updateDoc(restaurantDocRef, {
                                googleMapsUri: updatedData.googleMapsUri,
                                currentOpeningHours: updatedData.currentOpeningHours,
                                priceLevel: updatedData.priceLevel,
                                phoneNumber: updatedData.nationalPhoneNumber,
                                rating: updatedData.rating,
                                userRatingCount: updatedData.userRatingCount,
                                shortFormattedAddress: updatedData.shortFormattedAddress,
                                businessStatus: updatedData.businessStatus,
                                websiteUri: updatedData.websiteUri,
                            });

                            console.log(`Successfully updated data for restaurant: ${restaurant.id} (${restaurant.name}) with placeId: ${restaurant.placeId}`);
                        } catch (error) {
                            console.log('Error fetching places data:', error);
                        }
                    }
                }
                toast.success("Successfully updated all restaurants with place id information");
            }
            catch(error) {
                toast.error("error updating restaurants with place id information", error);
                console.error("error updating restaurants with place id information", error);
            }
            finally {
                setLoading(false);
            }
        }
    }

    return (
        <>
            {user && (
                <>
                    <Dialog open={open} onClose={handleClose}>
                        <DialogContent sx={{textAlign: "center", marginBottom: "10px"}}>
                            <Grid container direction="column" alignItems="center" spacing={2}>
                                <Grid item>
                                    <Avatar alt={user.displayName} src={user.photoURL}/>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h6">{user.displayName}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body1"><strong>Email:</strong> {user.email}</Typography>
                                </Grid>
                                {user.isAdmin && (
                                    <Grid item>
                                        <Typography variant="body1">
                                            <strong>FCM:</strong>
                                            <IconButton
                                                onClick={() => {
                                                    navigator.clipboard.writeText(fcmToken);
                                                }}
                                                aria-label="Copy FCM Token"
                                            >
                                                <FileCopyIcon/>
                                            </IconButton>
                                        </Typography>
                                    </Grid>
                                )}
                                {(user.uid === 'tIxCNXudhaQv73qiM0XyMIZjCh72' || user.uid === 'm0Cpg8ukNaMO6ZIMgP0DbKBKjH53') && (
                                    <Button variant='contained' onClick={updateData}>Update Places Data</Button>
                                )}

                                <Grid item>
                                    <Button variant="outlined" onClick={() => setEditPasswordOpen(true)}>Edit
                                        Password</Button>
                                </Grid>
                                <Grid item>
                                    <Button onClick={() => setDeleteUserOpen(true)} variant='contained' color='error'>
                                        Delete Account
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Close
                            </Button>
                        </DialogActions>
                        <Backdrop
                            sx={{ color: '#fff', zIndex: 999 }}
                            open={loading}
                        >
                            <CircularProgress sx={{ color: '#fff', zIndex: 999 }} color="inherit" />
                        </Backdrop>
                    </Dialog>
                    <EditPassword open={editPasswordOpen} handleClose={() => setEditPasswordOpen(false)} />
                    {deleteUserOpen && (
                        <DeleteUserModal open={deleteUserOpen} handleClose={() => setDeleteUserOpen(false)}/>
                    )}
                </>
            )}

        </>
    );
};

export default Profile;
