import React, { useEffect, useRef } from "react";
import { Chip, Grid, MenuItem, Typography } from "@mui/material";
import { SearchResultPaper } from "./SearchResultPaper";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { FULL_HALAL } from "../RequestRestaurant/halal_status";
import { setModalOpen, setModalRestaurant } from "../../redux/features/infoModal/infoModalSlice";
import { useDispatch } from "react-redux";

export const SearchResults = ({ searchResults, setSearchResults, showModal, setShowModal }) => {
    const dropdownRef = useRef(null);
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                !showModal &&
                !event.target.closest('.MuiInputBase-root') // Check if clicked inside search bar or its children
            ) {
                setSearchResults([]);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showModal, setSearchResults]);

    const handleItemClick = (restaurant) => {
        dispatch(setModalRestaurant(restaurant));
        dispatch(setModalOpen(true));
    };

    return (
        <SearchResultPaper ref={dropdownRef}>
            {searchResults.map((restaurant) => (
                <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    onClick={() => handleItemClick(restaurant)}
                    key={restaurant.id}
                >
                    <Grid container direction='column'>
                        <Grid item container  alignItems='center'>
                        <Typography variant="body1">{restaurant.name}</Typography>
                        {restaurant.distance && ( // Check if distance exists
                            <Typography display='inline' style={{fontSize: "0.8rem", color: "#666", marginLeft: '0.3rem'}}>
                                ({restaurant.distance.toFixed(2)} km)
                             </Typography>
                        )}
                        </Grid>
                        <Typography variant="body2">{restaurant.address}</Typography>
                    </Grid>

                    {!isMobile && restaurant.menu_status && (
                        <Chip
                            label={restaurant.menu_status === FULL_HALAL ? "Full Halal" : "Partial Halal"}
                            variant="outlined"
                            size="small"
                            sx={{
                                ml: 1, // Adjust margin as needed
                                backgroundColor: restaurant.menu_status === FULL_HALAL ? "#4CAF50" : "#FFA500",
                                color: "#FFFFFF",
                            }}
                        />
                    )}
                </MenuItem>
            ))}
        </SearchResultPaper>
    );
};
