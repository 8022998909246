import {Fragment, useState} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import {
  Autocomplete,
  Chip,
  DialogActions,
  DialogContentText,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {setError} from "../../redux/features/auth/authSlice";
import {toast} from "react-toastify";
import {canadianProvinces} from "./provinces";
import {FULL_HALAL, PARTIAL_HALAL, halalStates} from "./halal_status";
import {addDoc, collection, getDocs, query, where} from "firebase/firestore"; // Import `where` function
import {db, DB_PATHS} from "../../containers/Firebase/Firebase";
import {all_tags} from "../helpers/all_tags";
import CloseIcon from "@mui/icons-material/Close"

export const RequestRestaurant = ({open, handleClose}) => {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"))
    const [restaurantName, setRestaurantName] = useState("")
    const [city, setCity] = useState("Calgary")
    const [address, setAddress] = useState("")
    const [province, setProvince] = useState(canadianProvinces[0])
    const [halalStatus, setHalalStatus] = useState(halalStates[0])
    const [description, setDescription] = useState("") // New state for description
    const [tags, setTags] = useState([]);
    const [reels, setReels] = useState([]);
    const [instagramProfileTag, setInstagramProfileTag] = useState("");
    const [website, setWebsite] = useState("")
    const dispatch = useDispatch()
    const error = useSelector((state) => state.authReducer.error)
    const user = useSelector((state) => state.authReducer.user)


    const handleRequestRestaurant = async () => {
        if (
          restaurantName === "" ||
          address === "" ||
          city === "" ||
          province === "" ||
          halalStatus === ""
        ) {
          toast.error("Please fill in all required fields");
          return;
        }

        if (halalStatus === PARTIAL_HALAL && description === "") {
          toast.error("Please provide a description for partial halal status");
          return;
        }

        // Check if the restaurant already exists in the database
        // const existingRestaurantQuery = await getDocs(
        //   query(
        //     collection(db, DB_PATHS.RESTAURANTS),
        //     where("name", "==", restaurantName.trim())
        //   )
        // );

        // if (!existingRestaurantQuery.empty) {
        //   toast.error("This restaurant already exists on the website.");
        //   return;
        // }

        // Check for duplicate entry in requested restaurants
        const existingRequestQuery = await getDocs(
          query(
            collection(db, DB_PATHS.REQUESTED),
            where("name", "==", restaurantName.trim())
          )
        );

        if (!existingRequestQuery.empty) {
          // Check if any of the existing requests have the same name but different address
          const existingRequestDocs = existingRequestQuery.docs;
          const existingRequestWithSameAddress = existingRequestDocs.find(
            (doc) =>
              doc.data().address.toLowerCase() === address.trim().toLowerCase()
          );

          if (existingRequestWithSameAddress) {
            toast.error("A request for this restaurant already exists.");
            return;
          }
        }

        // Proceed with adding the new request
        let userId = user.uid;
        try {
          // Add the new restaurant request to Firestore
          await addDoc(collection(db, DB_PATHS.REQUESTED), {
            userId: userId,
            name: restaurantName,
            address: address,
            city: city,
            province: province,
            menu_status: halalStatus,
            description: description, // Include description in Firestore data
            website: website,
            reels: reels,
            instagramProfileTag: instagramProfileTag,
            tags: tags,
            userName: user.displayName,
          });

          handleSuccess();
        } catch {
          toast.error("Failed to request restaurant");
        }
      };

    const handleSuccess = () => {
        toast.success("Restaurant added to queue! We will get back to you soon.")
        handleDialogClose()
    }

    const handleDialogClose = () => {
        setRestaurantName("")
        setCity("Calgary")
        setAddress("")
        setProvince(canadianProvinces[0])
        setHalalStatus(halalStates[0])
        setDescription("") // Reset description field
        setTags([]);
        setReels([]);
        setWebsite("")
        dispatch(setError(null))
        handleClose()
    }

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleDialogClose}
                fullScreen={fullScreen}
                aria-labelledby="restaurant-dialog-title"
            >
                <DialogTitle id="restaurant-dialog-title">Request Restaurant</DialogTitle>
                <DialogContent>
                    <IconButton
                        style={{position: "absolute", top: 10, right: 10}}
                        onClick={handleDialogClose}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContentText>
                        Please enter Restaurant Details.
                    </DialogContentText>
                    <Typography variant="subtitle1" color="error">
                        {error}
                    </Typography>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Restaurant Name"
                        type="text"
                        fullWidth
                        required
                        value={restaurantName}
                        onChange={(e) => setRestaurantName(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="full-Address"
                        label="Address"
                        type="text"
                        fullWidth
                        required
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="email"
                        label="City"
                        type="text"
                        fullWidth
                        required
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                    />
                    <FormControl fullWidth sx={{mt: '0.5rem'}}>
                        <InputLabel id="province">Province *</InputLabel>
                        <Select
                            margin="dense"
                            id="province"
                            label="Province"
                            value={province}
                            onChange={(e) => setProvince(e.target.value)}
                            fullWidth
                            required
                        >
                            {canadianProvinces.map((province) => (
                                <MenuItem key={province} value={province}>
                                    {province}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{mt: '0.5rem'}}>
                        <InputLabel id="halal-status">Halal Status *</InputLabel>
                        <Select
                            id="halal-status"
                            label="Halal Status"
                            value={halalStatus}
                            onChange={(e) => {
                                if (e.target.value === FULL_HALAL) {
                                    setDescription("");
                                }
                                setHalalStatus(e.target.value);
                            }}
                            fullWidth
                            required
                        >
                            {halalStates.map((status) => (
                                <MenuItem key={status} value={status}>
                                    {status.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {halalStatus === PARTIAL_HALAL && ( // Render description field only if halal status is PARTIAL_HALAL
                    <TextField
                            margin="dense"
                            id="description"
                            label="Description"
                            type="text"
                            fullWidth
                            helperText="Please describe what items are halal"
                            required
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    )}
                    <Autocomplete
                        multiple
                        id="tags-filled"
                        sx={{mt: "0.5rem"}}
                        options={all_tags.map((option) => option)}
                        freeSolo
                        value={tags}
                        onChange={(event, newValue) => {
                            setTags(newValue);
                        }}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip variant="outlined" label={option} {...getTagProps({index})} />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                multiline
                                rows={1}
                                variant="outlined"
                                label="Tags"
                            />
                        )}
                    />
                    <TextField
                        margin="dense"
                        id="instagram-profile-tag"
                        label="Instagram Handle"
                        type="text"
                        fullWidth
                        value={instagramProfileTag}
                        onChange={(e) => setInstagramProfileTag(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="reels"
                        label="Featured Instagram Reel"
                        type="text"
                        fullWidth
                        value={reels}
                        onChange={(e) => setReels([e.target.value])}
                    />
                    <TextField
                        margin="dense"
                        id="website"
                        label="Website"
                        type="text"
                        fullWidth
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleDialogClose}
                        color="primary"
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleRequestRestaurant}
                        color="primary"
                        variant="contained"
                        sx={{backgroundColor: "#1E1E2F"}}
                    >
                        Request
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}
