import * as React from "react"
import {useEffect, useState} from "react"
import Box from "@mui/material/Box"
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { doc, updateDoc} from "firebase/firestore"
import {db, DB_PATHS} from "../../containers/Firebase/Firebase"

export const EditNoteComponent = ({open, handleClose, note, refetchNotes }) => {
    const [editableContent, setEditableContent] = useState(note);

    useEffect(() => {
        setEditableContent(note);
    }, [note])

    const handleInputChange = (event, field) => {
        setEditableContent({
            ...editableContent,
            [field]: event.target.value,
        })
    }

    const handleSaveChanges = async () => {
        if (
            !editableContent.text
        ) {
            alert("Please fill in all required fields");
            return;
        }

        let updatedContent = {
            text: editableContent.text,
            createdBy: editableContent.editableContent || ""
        }


        try {
            const notesRef = doc(db, DB_PATHS.NOTES, note.id);

            await updateDoc(notesRef, {
                ...updatedContent,
            })

            refetchNotes();
            handleClose();
        } catch (error) {
            console.error(`Error uploading document `, error);
        }
    }

    return (
    <>
        <Dialog
            open={open}
            maxWidth="md"
            fullWidth
            onClose={handleClose}
        >
            <DialogTitle id="edit dialog">Edit Note</DialogTitle>
            <DialogContent>
            <IconButton
                    style={{position: "absolute", top: 10, right: 10}}
                    onClick={handleClose}
                >
                    <CloseIcon/>
                </IconButton>
                    <>
                        <TextField
                            sx={{
                                width: '100%',
                                marginTop: 2,
                                fontSize: '1.1rem',
                                '.MuiInputBase-root': {
                                    padding: '16px',
                                    fontSize: '1rem'
                                },
                            }}
                            id="note"
                            label="Note"
                            fullWidth
                            value={editableContent?.text || ""}
                            onChange={(e) => handleInputChange(e, 'text')}
                        />
                        <Box sx={{display: 'flex', justifyContent: 'flex-end', marginTop: 2}}>
                            <Button onClick={handleClose} color="primary" variant="outlined">
                                Cancel
                            </Button>
                            <Button onClick={handleSaveChanges} color="primary" variant="contained"
                                    sx={{backgroundColor: "#1E1E2F", marginLeft: 2}}>
                                Save
                            </Button>
                        </Box>
                    </>

            </DialogContent>
        </Dialog>
    </>
    )
}
