import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import {Divider, List, ListItemIcon, SwipeableDrawer} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import InfoIcon from "@mui/icons-material/Info";
import Typography from "@mui/material/Typography";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import FavoriteIcon from "@mui/icons-material/Favorite";
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import LogoutIcon from '@mui/icons-material/Logout';
import {logout} from "../../redux/features/auth/authThunks";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";

const MobileMenu = ({
                        isAdmin,
                        user,
                        isMenuOpen,
                        setMenuOpen,
                        setAboutUsOpen,
                        setContactUsOpen,
                        setSignInOpen,
                        setRequestRestaurantOpen,
                        setRestaurantDirectoryOpen,
                        setPendingRestaurantsOpen,
                        setMissingInfo,
                        navigateToNotes,
                        setFavoriteOpen,
                        setWishlistOpen
                    }) => {
    const dispatch = useDispatch();

    const handleLogout = () => {
        setMenuOpen(false);
        dispatch(
            logout(() => {
                toast.success("Logged out successfully");
            })
        );
    };

    const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

    return (
        <Box sx={{flexGrow: 1, display: {xs: "flex", md: "none"}}}>
            <IconButton
                size="large"
                onClick={() => setMenuOpen(true)}
                color="inherit"
            >
                <MenuIcon/>
            </IconButton>
            <SwipeableDrawer
                open={isMenuOpen}
                onOpen={() => setMenuOpen(true)}
                onClose={() => setMenuOpen(false)}
                disableDiscovery={iOS} // iOS has a "swipe to go back" feature that interferes with the discovery feature, so discovery has to be disabled.
            >
                <Avatar
                    src={"black-logo.svg"}
                    sx={{width: 200, height: 100}}
                />
                <Divider/>
                <Box sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                    <List sx={{flexGrow: 1}}>
                        <MenuItem
                            key={"about-us"}
                            onClick={() => {
                                setAboutUsOpen(true);
                            }}
                        >
                            <ListItemIcon>
                                <InfoIcon fontSize="small" sx={{color: '#00C2FF'}}/>
                            </ListItemIcon>
                            <Typography textAlign="center">About Us</Typography>
                        </MenuItem>
                        <MenuItem
                            key={"contact-us"}
                            onClick={() => {
                                setContactUsOpen(true);
                            }}
                        >
                            <ListItemIcon>
                                <ContactMailIcon fontSize="small" sx={{color: '#00C2FF'}}/>
                            </ListItemIcon>
                            <Typography textAlign="center">Contact Us</Typography>
                        </MenuItem>
                        <MenuItem
                            key={"request restaurant"}
                            onClick={() => {
                                if (user === null) {
                                    setSignInOpen(true);
                                    return;
                                }
                                setRequestRestaurantOpen(true);
                            }}
                        >
                            <ListItemIcon>
                                <RestaurantIcon fontSize="small" sx={{color: '#00C2FF'}}/>
                            </ListItemIcon>
                            <Typography textAlign="center">Request Restaurant</Typography>
                        </MenuItem>
                        <MenuItem
                            key={"restaurant-directory"}
                            onClick={() => {
                                setRestaurantDirectoryOpen(true);
                            }}
                        >
                            <ListItemIcon>
                                <MenuBookIcon fontSize="small" sx={{color: '#00C2FF'}}/>
                            </ListItemIcon>
                            <Typography textAlign="center">Directory</Typography>
                        </MenuItem>
                        {user && (
                            <>
                                <Divider/>
                                <MenuItem
                                    key={"favourite"}
                                    onClick={() => {
                                        setFavoriteOpen(true);
                                    }}
                                >
                                    <ListItemIcon>
                                        <FavoriteIcon fontSize="small" sx={{color: '#00C2FF'}}/>
                                    </ListItemIcon>
                                    <Typography textAlign="center">Favourites</Typography>
                                </MenuItem>
                                <MenuItem
                                    key={"interested"}
                                    onClick={() => {
                                        setWishlistOpen(true);
                                    }}
                                >
                                    <ListItemIcon>
                                        <BookmarkAddIcon fontSize="small" sx={{color: '#00C2FF'}}/>
                                    </ListItemIcon>
                                    <Typography textAlign="center">Interested</Typography>
                                </MenuItem>
                            </>
                        )}
                        {isAdmin && (
                            <>
                                <Divider/>
                                <MenuItem
                                    key={"pending"}
                                    onClick={() => {
                                        setPendingRestaurantsOpen(true);
                                    }}
                                >
                                    <ListItemIcon>
                                        <PendingActionsIcon fontSize="small" sx={{color: '#00C2FF'}}/>
                                    </ListItemIcon>
                                    <Typography textAlign="center">Pending Restaurants</Typography>
                                </MenuItem>
                                <MenuItem
                                    key={"missing"}
                                    onClick={() => {
                                        setMissingInfo(true);
                                    }}
                                >
                                    <ListItemIcon>
                                        <ReportProblemIcon fontSize="small" sx={{color: '#00C2FF'}}/>
                                    </ListItemIcon>
                                    <Typography textAlign="center">Missing Info</Typography>
                                </MenuItem>
                                <MenuItem
                                    key={"notes"}
                                    onClick={() => {
                                        navigateToNotes();
                                    }}
                                >
                                    <ListItemIcon>
                                        <DocumentScannerIcon fontSize="small" sx={{color: '#00C2FF'}}/>
                                    </ListItemIcon>
                                    <Typography textAlign="center">Notes</Typography>
                                </MenuItem>
                            </>
                        )}
                    </List>

                    {/* Bottom-aligned items */}
                    <Box sx={{mt: 'auto'}}> {/* Add padding for spacing */}
                        {user && (
                            <MenuItem
                                key={"missing"}
                                onClick={handleLogout}
                            >
                                <ListItemIcon>
                                    <LogoutIcon fontSize="small" sx={{color: '#00C2FF'}}/>
                                </ListItemIcon>
                                <Typography textAlign="center">Logout</Typography>
                            </MenuItem>
                        )}
                    </Box>
                </Box>
            </SwipeableDrawer>
        </Box>
    )
};

export default MobileMenu;
