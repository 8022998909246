export function filterRestaurants(restaurantsToUse, searchString) {
    const searchLower = searchString.toLowerCase();

    return restaurantsToUse.filter((restaurant) =>
        (restaurant.name && restaurant.name.toLowerCase().includes(searchLower)) ||
        (restaurant.address && restaurant.address.toLowerCase().includes(searchLower)) ||
        (restaurant.menu_status && restaurant.menu_status.replace('_', ' ').toLowerCase().includes(searchLower)) ||
        (restaurant.tags && restaurant.tags.some(tag => tag.toLowerCase().includes(searchLower)))
    );
}
