import React from 'react';
import { useSelector } from 'react-redux';
import InfoModal from './InfoModal';

function InfoModalWrapper() {
    const showModal = useSelector((state) => state.infoModalReducer.open);

    return showModal ? <InfoModal /> : null;
}

export default React.memo(InfoModalWrapper);