import {Fragment, useEffect, useState} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import {Box, DialogContentText, Divider, IconButton, TextField, Typography,} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {setError, setUser} from "../../redux/features/auth/authSlice";
import {toast} from "react-toastify";
import {googleSignIn, handleAdmin, login} from "../../redux/features/auth/authThunks";
import GoogleButton from "react-google-button";
import CloseIcon from "@mui/icons-material/Close";
import ForgotPassword from "./ForgotPassword";
import Register from "./Register";
import AppleSignInButton from "react-apple-signin-auth";
import { getRedirectResult, GoogleAuthProvider, OAuthProvider, signInWithRedirect } from "firebase/auth";
import { auth } from "../../containers/Firebase/Firebase";
import { isDevelopment } from "../helpers/environment_helper";

export default function SignIn({open, handleClose}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [registerOpen, setRegisterOpen] = useState(false);
    const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
    const error = useSelector((state) => state.authReducer.error);
    const dispatch = useDispatch();

    const handleSignIn = () => {
        if (email === "" || password === "") {
            dispatch(setError("Please fill in all fields"));
            return;
        }

        dispatch(login(email, password, handleSuccess));
    };

    useEffect(() => {
        const checkRedirectResult = async () => {
            try {
                const result = await getRedirectResult(auth);
                if (result) {
                    let isAdmin = await handleAdmin(result.user);
                    const user = {...result.user, isAdmin: isAdmin};
                    dispatch(setUser(user));
                    handleSuccess();
                }
            } catch (error) {
                console.error('Error during redirect result handling:', error);
                toast.error("Error during redirect result handling");
            }
        };

        checkRedirectResult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleGoogleSignIn = () => {
        if (isDevelopment()) {
            dispatch(googleSignIn(handleSuccess));
        } else {
            const provider = new GoogleAuthProvider();
            signInWithRedirect(auth, provider).catch((error) => {
                console.error('Error during sign-in redirect:', error);
                toast.error('Error during sign-in redirect');
            });
        }
    };

    const handleAppleSignIn = () => {
        const provider = new OAuthProvider('apple.com');
        signInWithRedirect(auth, provider).catch((error) => {
            console.error('Error during sign-in redirect:', error);
            toast.error('Error during sign-in redirect');
        });
    }
    const handleSuccess = () => {
        toast.success("Logged in successfully");
        handleDialogClose();
    };

    const handleDialogClose = () => {
        setEmail("");
        setPassword("");
        dispatch(setError(null));
        handleClose();
    };

    const handleCreateAccountClick = () => {
        handleDialogClose();
        dispatch(setError(null));
        setRegisterOpen(true);
    }

    const handleForgotPassword = () => {
        handleDialogClose();
        dispatch(setError(null));
        setForgotPasswordOpen(true);
    }

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleDialogClose}
                fullScreen={fullScreen}
                aria-labelledby="register-dialog-title"
            >
                <DialogTitle id="register-dialog-title">
                    Login
                    {fullScreen && ( // Render close button only on mobile view
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleDialogClose}
                            aria-label="close"
                            sx={{position: 'absolute', right: 15, top: 8}}
                        >
                            <CloseIcon/>
                        </IconButton>
                    )}
                </DialogTitle>
                <DialogContent>
                    <Box sx={{display: "flex", justifyContent: "center", mt: 1}}>
                        <AppleSignInButton onClick={handleAppleSignIn}/>
                    </Box>
                    <br/>
                    <Divider><Typography>Or</Typography></Divider>
                    <br/>
                    <Box sx={{display: "flex", justifyContent: "center", mt: 1}}>
                        <GoogleButton onClick={handleGoogleSignIn}/>
                    </Box>
                    <br/>
                    <Divider><Typography>Or</Typography></Divider>
                    <br/>
                    <Typography variant="subtitle1" color="error">
                        {error}
                    </Typography>
                    <TextField
                        margin="dense"
                        id="email"
                        label="Email Address"
                        type="email"
                        fullWidth
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="password"
                        label="Password"
                        type="password"
                        fullWidth
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                        fullWidth
                        onClick={handleSignIn}
                        color="primary"
                        variant="contained"
                        sx={{backgroundColor: "#1E1E2F"}}
                        size={"large"}
                    >
                        LOGIN
                    </Button>
                    <br/>
                    <br/>
                    <DialogContentText sx={{textAlign: "center"}}>
                        Looking to{" "}
                        <Typography
                            component="a"
                            color="primary"
                            onClick={handleCreateAccountClick}
                            sx={{textDecoration: "none", "&:hover": {textDecoration: "underline", 'cursor': 'pointer'}}}
                        >
                            create an account
                        </Typography>
                        {"?"}
                    </DialogContentText>
                    <DialogContentText sx={{textAlign: "center"}}>
                        <Typography
                            component="a"
                            color="primary"
                            onClick={handleForgotPassword}
                            sx={{
                                textDecoration: "none",
                                "&:hover": {textDecoration: "underline", 'cursor': 'pointer'},
                            }}
                        >
                            Forgot your password?
                        </Typography>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <ForgotPassword open={forgotPasswordOpen} onClose={() => {
                setForgotPasswordOpen(false)
            }}/>
            <Register
                open={registerOpen}
                handleClose={() => setRegisterOpen(false)}
            />
        </Fragment>
    );
}
