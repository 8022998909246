import React, {useState} from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import SignIn from "../SignIn/SignIn";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {logout} from "../../redux/features/auth/authThunks";
import ContactUsForm from "../FormSpreeForms/ContactUs/ContactUs";
import SearchIcon from "@mui/icons-material/Search";
import FavoriteRestaurants from "./FavoriteRestaurants";
import {Searchbar} from "../Search/Searchbar";
import Profile from "../Profile/Profile";
import {RequestRestaurant} from "../RequestRestaurant/RequestRestaurant";
import WishlistRestaurants from "./WishlistRestaurants";
import PendingRestaurants from "../PendingRestaurants/PendingRestaurants";
import AboutUs from "../About Us/AboutUs";
import Hidden from "@mui/material/Hidden";
import RestaurantDirectory from "../RestaurantDirectory/RestaurantDirectory";
import BottomMenu from "./BottomMenu";
import {BottomNavigationAction} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {NearbyRestaurants} from "../NearbyRestaurants/NearbyRestaurants";
import {MissingInfo} from "../MissingInfo/MissingInfo";
import MobileMenu from "./MobileMenu";
import SearchMobile from "../Search/SearchMobile";
import { useNavigate } from "react-router-dom";

export default function Navbar() {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [signInOpen, setSignInOpen] = useState(false);
    const [contactUsOpen, setContactUsOpen] = useState(false);
    const [aboutUsOpen, setAboutUsOpen] = useState(false);
    const [restaurantDirectoryOpen, setRestaurantDirectoryOpen] = useState(false);
    const [favoriteOpen, setFavoriteOpen] = useState(false);
    const [wishlistOpen, setWishlistOpen] = useState(false);
    const [searchVisible, setSearchVisible] = useState(false);
    const [mobileSearch, setMobileSearch] = useState(false);
    const [profileOpen, setProfileOpen] = useState(false);
    const [requestRestaurantOpen, setRequestRestaurantOpen] = useState(false);
    const [pendingRestaurantsOpen, setPendingRestaurantsOpen] = useState(false);
    const [missingInfo, setMissingInfo] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [nearbyRestaurantsOpen, setNearbyRestaurantsOpen] = useState(false);
    const user = useSelector((state) => state.authReducer.user);
    const isAdmin = user?.isAdmin;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleSearchToggle = () => {
        setSearchVisible(false);
    };

    const handleLogout = () => {
        setAnchorElUser(null);
        dispatch(
            logout(() => {
                toast.success("Logged out successfully");
            })
        );
    };

    const navigateToNotes = () => {
        navigate('/notes');
    }

    const Logo = () => (
        <Hidden mdDown>
            <img
                src="/white-logo.svg"
                alt="Icon"
                style={{
                    maxWidth: "300px", // Resize the image to fit its container width
                    height: "auto", // Maintain aspect ratio
                }}
            />
        </Hidden>
    );

    const MobileLogo = () => (
        <Hidden mdUp>
            <div style={{
                display: 'flex',
                flexGrow: 1, // Allow the container to grow to fill available space
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%' // Ensure the container takes up the full height
            }}>
                <img
                    src="/white-logo-half.svg"
                    alt="Small Icon"
                    style={{
                        maxWidth: "100%", // Resize the image to fit its container width
                        height: "auto", // Maintain aspect ratio
                    }}
                />
            </div>
        </Hidden>
    );

    const DesktopMenu = () => {
        return <>
            <Box sx={{marginTop: '0.8rem', flexGrow: 1, display: {xs: "none", md: "flex"}}}>
                <Button
                    key={"about-us"}
                    onClick={() => {
                        setAboutUsOpen(true);
                    }}
                    sx={{my: 2, color: "white", display: "block"}}
                >
                    About Us
                </Button>
                <Button
                    key={"contact-us"}
                    onClick={() => {
                        setContactUsOpen(true);
                    }}
                    sx={{my: 2, color: "white", display: "block"}}
                >
                    Contact Us
                </Button>
                <Button
                    key={"add-restaurants"}
                    onClick={() => {
                        if (user === null) {
                            setSignInOpen(true);
                            return;
                        }

                        setRequestRestaurantOpen(true);
                    }}
                    sx={{my: 2, color: "white", display: "block"}}
                >
                    Request Restaurant
                </Button>
                <Button
                    key={"restaurant-directory"}
                    onClick={() => {
                        setRestaurantDirectoryOpen(true);
                    }}
                    sx={{my: 2, color: "white", display: "block"}}
                >
                    Directory
                </Button>
                {isAdmin && (
                    <>
                        <Button
                            key={"pending"}
                            onClick={() => {
                                setPendingRestaurantsOpen(true);
                            }}
                            sx={{my: 2, color: '#EEEEEE', display: "block"}}
                        >
                            Pending Restaurants
                        </Button>
                        <Button
                            key={"missing"}
                            onClick={() => {
                                setMissingInfo(true);
                            }}
                            sx={{my: 2, color: '#EEEEEE', display: "block"}}
                        >
                            Missing Info
                        </Button>
                        <Button
                            key={"notes"}
                            onClick={() => {
                                navigateToNotes()
                            }}
                            sx={{my: 2, color: '#EEEEEE', display: "block"}}
                        >
                            Notes
                        </Button>
                    </>
                )}
            </Box>
            <Box sx={{mr: '1rem', display: {xs: "none", md: "flex"}}}>
                <Button
                    key={"search"}
                    onClick={() => {
                        setSearchVisible(true);
                    }}
                    sx={{my: 2, color: "#00C2FF"}}
                >
                    <Typography><SearchIcon/></Typography>Search
                </Button>
            </Box>
        </>;
    }

    return (
        <>
            <AppBar
                position="fixed"
                sx={{
                    backgroundColor: "#1E1E2F",
                    height: "4.5rem",
                    flexDirection: {xs: 'row'},
                    alignItems: {xs: 'center'}
                }}
            >
                <Toolbar sx={{height: '100%', padding: '0 1rem', margin: '0 auto', width: '100%'}} disableGutters>
                    <div style={{overflow: 'hidden', height: '100%', display: 'flex', alignItems: 'center'}}>
                        <Logo/>
                    </div>

                    <MobileMenu
                        user={user}
                        isAdmin={isAdmin}
                        isMenuOpen={isMobileMenuOpen}
                        setMenuOpen={setIsMobileMenuOpen}
                        setAboutUsOpen={setAboutUsOpen}
                        setContactUsOpen={setContactUsOpen}
                        setSignInOpen={setSignInOpen}
                        setRequestRestaurantOpen={setRequestRestaurantOpen}
                        setRestaurantDirectoryOpen={setRestaurantDirectoryOpen}
                        setPendingRestaurantsOpen={setPendingRestaurantsOpen}
                        setMissingInfo={setMissingInfo}
                        setWishlistOpen={setWishlistOpen}
                        navigateToNotes={navigateToNotes}
                        setFavoriteOpen={setFavoriteOpen}
                    />
                    <div style={{overflow: 'hidden', height: '100%', display: 'flex', alignItems: 'center'}}>
                        <MobileLogo/>
                    </div>

                    <DesktopMenu/>

                    {/* Login/Logout/User profile */}
                    {user ? (
                        <Box sx={{flexGrow: 0}}>
                            {/* Avatar for Desktop View */}
                            <Hidden mdDown>
                                <Tooltip title="Open settings">
                                    <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                                        <Avatar alt={user.displayName} src={user.photoURL}/>
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{mt: "45px"}}
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    <MenuItem
                                        onClick={() => {
                                            setAnchorElUser(false);
                                            setProfileOpen(true);
                                        }}
                                    >
                                        <Typography textAlign="center">Profile</Typography>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            setFavoriteOpen(true);
                                        }}
                                    >
                                        <Typography textAlign="center">Favourites</Typography>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            setWishlistOpen(true);
                                        }}
                                    >
                                        <Typography textAlign="center">Interested</Typography>
                                    </MenuItem>
                                    <MenuItem onClick={handleLogout}>
                                        <Typography textAlign="center">Logout</Typography>
                                    </MenuItem>
                                </Menu>
                            </Hidden>

                            {/* Avatar for Mobile View */}
                            <Hidden mdUp>
                                <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                                    <Avatar alt={user.displayName}
                                            src={user.photoURL}
                                            onClick={() => {
                                                setProfileOpen(true);
                                            }}/>
                                </IconButton>
                            </Hidden>
                        </Box>
                    ) : (
                        <Box sx={{flexGrow: 0}}>
                            {/* Button for Desktop View */}
                            <Hidden mdDown>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        color: "#FFFFFF", // White font color
                                        borderColor: "#FFFFFF", // White border color
                                        "&:hover": {
                                            borderColor: "#FFFFFF", // White border color on hover
                                        },
                                    }}
                                    onClick={() => setSignInOpen(true)}
                                >
                                    Login
                                </Button>
                            </Hidden>

                            {/* Avatar for Mobile View */}
                            <Hidden mdUp>
                                <IconButton onClick={() => setSignInOpen(true)} sx={{p: 0}}>
                                    <Avatar
                                        sx={{
                                            bgcolor: 'black',
                                            color: 'white',
                                            border: '2px solid transparent', // Set border thickness to 2px
                                            borderImage: 'conic-gradient(#00C2FF 0% 25%, white 25% 50%, #00C2FF 50% 75%, white 75% 100%) 1',
                                        }}
                                    >
                                        ?
                                    </Avatar>
                                </IconButton>
                            </Hidden>
                        </Box>
                    )}
                    <Searchbar visible={searchVisible} onHide={handleSearchToggle} setSearchTerm={setSearchTerm}
                               searchTerm={searchTerm}/>
                </Toolbar>
                <SignIn
                    open={signInOpen}
                    handleClose={() => setSignInOpen(false)}
                />
                <AboutUs open={aboutUsOpen} handleClose={() => setAboutUsOpen(false)}/>
                <ContactUsForm
                    open={contactUsOpen}
                    handleClose={() => setContactUsOpen(false)}
                />
                <RequestRestaurant
                    open={requestRestaurantOpen}
                    handleClose={() => setRequestRestaurantOpen(false)}
                />
                <RestaurantDirectory
                    open={restaurantDirectoryOpen}
                    handleClose={() => setRestaurantDirectoryOpen(false)}
                />
                {pendingRestaurantsOpen && isAdmin && (
                    <PendingRestaurants
                        open={pendingRestaurantsOpen}
                        handleClose={() => setPendingRestaurantsOpen(false)}
                    />
                )}
                {missingInfo && isAdmin && (
                    <MissingInfo
                        open={missingInfo}
                        handleClose={() => setMissingInfo(false)}
                    />
                )}
                {favoriteOpen && (
                    <FavoriteRestaurants
                        open={favoriteOpen}
                        handleClose={() => {
                            setFavoriteOpen(false);
                        }}
                    />
                )}
                {wishlistOpen && (
                    <WishlistRestaurants
                        open={wishlistOpen}
                        handleClose={() => {
                            setWishlistOpen(false);
                        }}
                    />
                )}
                <Profile open={profileOpen} handleClose={() => setProfileOpen(false)}/>
            </AppBar>
            {/* Render Button Menu only on mobile */}
            <Hidden mdUp>
                <SearchMobile isMenuOpen={mobileSearch} setMenuOpen={setMobileSearch}/>
                <BottomMenu>
                    <BottomNavigationAction label="Nearby" icon={<LocationOnIcon/>} onClick={() => {
                        setNearbyRestaurantsOpen(true)
                    }}/>
                    <BottomNavigationAction label="Search" icon={<SearchIcon/>} onClick={() => {
                        setMobileSearch(true)
                    }}/>
                </BottomMenu>
                {nearbyRestaurantsOpen && (
                    <NearbyRestaurants
                        open={nearbyRestaurantsOpen}
                        handleClose={() => {
                            setNearbyRestaurantsOpen(false);
                        }}
                    />
                )}
            </Hidden>
        </>
    );
}
