import React, {useState} from "react";
import {Button, Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography} from "@mui/material";
import {AttachMoney, Language, LocationOn, Phone, Star} from "@mui/icons-material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const PlacesApiData = ({placeId}) => {
    const [placeData, setPlaceData] = useState(null);
    const [error, setError] = useState(null);

    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    const handleFetchData = async () => {
        const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
        const url = `https://places.googleapis.com/v1/places/${placeId}?fields=id,photos,shortFormattedAddress,googleMapsUri,businessStatus,currentOpeningHours,nationalPhoneNumber,priceLevel,rating,userRatingCount,websiteUri&key=${apiKey}`;

        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const placeData = await response.json();
            setPlaceData(placeData);
        } catch (error) {

            setError(error);
        }
    };

    const PriceLevel = {
        EXPENSIVE: 'PRICE_LEVEL_EXPENSIVE',
        FREE: 'PRICE_LEVEL_FREE',
        INEXPENSIVE: 'PRICE_LEVEL_INEXPENSIVE',
        MODERATE: 'PRICE_LEVEL_MODERATE',
        VERY_EXPENSIVE: 'PRICE_LEVEL_VERY_EXPENSIVE'
    };

    const priceLevelToDollarSigns = {
        [PriceLevel.FREE]: '$',
        [PriceLevel.INEXPENSIVE]: '$',
        [PriceLevel.MODERATE]: '$$',
        [PriceLevel.EXPENSIVE]: '$$$',
        [PriceLevel.VERY_EXPENSIVE]: '$$$$'
    };


    return (
        <div>
            {placeData === null && (
                <Button onClick={handleFetchData} variant={'text'}>
                    Real Time Data ...
                </Button>
            )}
            {error && <Typography color="error">{error.message}</Typography>}
            {placeData && (
                <List>
                    {placeData.googleMapsUri && (
                        <ListItemButton onClick={() => {
                            window.open(placeData.googleMapsUri)
                        }}>
                            <ListItemIcon>
                                <LocationOn/>
                            </ListItemIcon>
                            <ListItemText primary={`${placeData.shortFormattedAddress}`}/>
                        </ListItemButton>
                    )}
                    {placeData.currentOpeningHours && (
                        <List component="nav">
                            <ListItemButton onClick={handleClick}>
                                <ListItemIcon>
                                    <AccessTimeIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Business Hours"/>
                                {open ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                            </ListItemButton>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {placeData.currentOpeningHours.weekdayDescriptions.map((day, index) => (
                                        <ListItemButton key={index} sx={{pl: 4}}>
                                            <ListItemText primary={day}/>
                                        </ListItemButton>
                                    ))}
                                </List>
                            </Collapse>
                        </List>
                    )}
                    {placeData.nationalPhoneNumber && (
                        <ListItemButton>
                            <ListItemIcon>
                                <Phone/>
                            </ListItemIcon>
                            <ListItemText primary={`${placeData.nationalPhoneNumber}`}/>
                        </ListItemButton>
                    )}
                    {placeData.priceLevel !== undefined && (
                        <ListItemButton>
                            <ListItemIcon>
                                <AttachMoney/>
                            </ListItemIcon>
                            <ListItemText primary={`${priceLevelToDollarSigns[placeData.priceLevel]}`}/>
                        </ListItemButton>
                    )}
                    {placeData.rating !== undefined && (
                        <ListItemButton>
                            <ListItemIcon>
                                <Star/>
                            </ListItemIcon>
                            <ListItemText
                                primary={`${placeData.rating} (${placeData.userRatingCount} reviews)`}/>
                        </ListItemButton>
                    )}
                    {placeData.websiteUri && (
                        <ListItemButton onClick={() => window.open(placeData.websiteUri)}>
                            <ListItemIcon>
                                <Language/>
                            </ListItemIcon>
                            <ListItemText
                                primary={`${placeData.websiteUri.replace(/\/$/, '').replace(/^(https?:\/\/)?(www\.)?/, '')}`}/>
                        </ListItemButton>
                    )}
                </List>
            )}
        </div>
    );
};

export default PlacesApiData;
