import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

const TitleBarBelowImageList = ({itemOnClick}) => (
    <ImageList sx={{width: "100%"}}>
        {itemData.map((item) => (
            <ImageListItem key={item.title} onClick={() => itemOnClick(item.restaurantId)}>
                <img
                    srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    src={`${item.img}?w=248&fit=crop&auto=format`}
                    alt={item.title}
                    loading="lazy"
                />
                <ImageListItemBar
                    title={item.title}
                    subtitle={<span>{item.name}</span>}
                    position="below"
                    sx={{paddingLeft: 1}}
                />
            </ImageListItem>
        ))}
    </ImageList>
)

const itemData = [
    {
        img: 'https://plus.unsplash.com/premium_photo-1723809701668-325bc15b5137',
        title: 'Bangladeshi',
        name: 'Utsav Sweets and Restaurant',
        restaurantId: 'eacbd9bfb789f68a',
    },
    {
        img: 'https://images.unsplash.com/photo-1586805608485-add336722759',
        title: 'Breakfast',
        name: 'Namo Cafe Bistro',
        restaurantId: '7d753a452e57487f',
    },
    {
        img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
        title: 'Burger',
        name: "Flipp'n Burgers",
        restaurantId: 'a2bb2100f0564fb2',
    },
    {
        img: 'https://plus.unsplash.com/premium_photo-1678715022988-417bbb94e3df',
        title: 'Dessert',
        name: 'D Spot Dessert Cafe',
        restaurantId: '51c9ae407444521f',
    },
    {
        img: 'https://plus.unsplash.com/premium_photo-1661412855930-2936cf94e57a',
        title: 'East Asian',
        name: 'Golden Dragon Hot Pot',
        restaurantId: '20ea86d1517d4477',
    },
    {
        img: 'https://images.unsplash.com/photo-1504973960431-1c467e159aa4',
        title: 'Fine Dining',
        name: 'Ruth\'s Chris Steak House\n',
        restaurantId: 'a1e7751fbf9b14dd',
    },
    {
        img: 'https://images.unsplash.com/photo-1709164632728-8a943456dd0a',
        title: 'Korean Fried Chicken',
        name: 'Wow Chicken',
        restaurantId: '62eacbe18708612a',
    },
    {
        img: 'https://images.unsplash.com/photo-1612390701898-c71df1887259',
        title: 'Nashville Hot Chicken',
        name: 'Nim\'s Fried Chicken',
        restaurantId: '33b42bcf7ae13ac9',
    },
    {
        img: 'https://images.unsplash.com/photo-1528137871618-79d2761e3fd5',
        title: 'Pizza',
        name: "Seniore's Pizza",
        restaurantId: '8d161dc9c2cdefcf',
    },
    {
        img: 'https://images.unsplash.com/photo-1586805608485-add336722759',
        title: 'Poutine',
        name: 'PrimeTime',
        restaurantId: 'b6ad104343528d01',
    },
    {
        img: 'https://images.unsplash.com/photo-1642783944285-b33b18ef6c3b',
        title: 'Shawarma',
        name: 'Damascus',
        restaurantId: 'e03a2ec8b24dbac8',
    },
    {
        img: 'https://plus.unsplash.com/premium_photo-1694141253763-209b4c8f8ace',
        title: 'South Asian',
        name: 'Faisal Kebab Hut',
        restaurantId: '192628d276b233bc',
    },
    {
        img: 'https://plus.unsplash.com/premium_photo-1682097617396-e510665e0dc8',
        title: 'Turkish',
        name: 'Turkish Kebab House',
        restaurantId: '9bc9caa938643603',
    },
    {
        img: 'https://plus.unsplash.com/premium_photo-1664478276162-46c39b3557c3',
        title: 'Vietnamese',
        name: 'Pho Daddy',
        restaurantId: '3af08afe0c4f1bbb',
    },
];



export default TitleBarBelowImageList;