export const handleFetchPlaceData = async (placeId) => {
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const url = `https://places.googleapis.com/v1/places/${placeId}?fields=id,photos,shortFormattedAddress,googleMapsUri,businessStatus,currentOpeningHours,nationalPhoneNumber,priceLevel,rating,userRatingCount,websiteUri&key=${apiKey}`;

    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const placeData = await response.json();
        return placeData;
    } catch (error) {
        console.error(error);
        return null;
    }
};
