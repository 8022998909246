import * as React from "react";
import {useEffect, useRef, useState} from "react";
import Typography from "@mui/material/Typography";
import {
    Box,
    Button,
    Chip,
    Collapse,
    Divider,
    Grid,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InstagramPostEmbed from "../InstagramPostEmbed/InstagramPostEmbed";
import {doc, setDoc} from "firebase/firestore";
import {useDispatch, useSelector} from "react-redux";
import {db, DB_PATHS} from "../../containers/Firebase/Firebase";
import {addToFavorites, removeFromFavorites,} from "../../redux/features/favorites/favoritesSlice";
import {addToVisited, removeFromVisited,} from "../../redux/features/visited/visitedSlice";
import ReelsPlaceholder from "./ReelsPlaceholder";
import SignIn from "../SignIn/SignIn";
import {getAnalytics, logEvent} from "firebase/analytics";
import {addToWishlist, removeFromWishlist} from "../../redux/features/wishlist/wishlistSlice";
import Dialog from "@mui/material/Dialog";
import ShareIcon from '@mui/icons-material/Share';
import useMediaQuery from "@mui/material/useMediaQuery";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {useTheme} from "@mui/material/styles";
import {UserIcons} from "./UserIcons";
import {EditRestaurant} from "../EditRestaurant/EditRestaurant";
import {FULL_HALAL, PARTIAL_HALAL} from "../RequestRestaurant/halal_status";
import {CertificatePopup} from "./CertificatePopup";
import PlacesApiData from "./PlacesApiData";
import {toast} from "react-toastify"
import {isMobileDevice} from "../helpers/mobile_helper";
import {AttachMoney, Phone, Star} from "@mui/icons-material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InstagramProfile from "./InstagramProfile";
import {setModalOpen, setModalRestaurant, setPreviewMode} from "../../redux/features/infoModal/infoModalSlice";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DirectionsIcon from "@mui/icons-material/Directions";
import LanguageIcon from "@mui/icons-material/Language";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";

export default function InfoModal() {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const user = useSelector((state) => state.authReducer.user);
    const isAdmin = user?.isAdmin;
    const dispatch = useDispatch();
    const favorites = useSelector(
        (state) => state.favoritesReducer.favoriteRestaurants
    );
    const visited = useSelector(
        (state) => state.visitedReducer.visitedRestaurants
    );
    const open = useSelector(
        (state) => state.infoModalReducer.open
    );
    const modalContent = useSelector(
        (state) => state.infoModalReducer.restaurantData
    );
    const previewMode = useSelector(
        (state) => state.infoModalReducer.previewMode
    );
    const wishlist = useSelector(state => state.wishlistReducer.wishlistRestaurants);
    const [signInOpen, setSignInOpen] = useState(false);
    const isFavorite = favorites?.find((favId) => favId === modalContent.id);
    const isVisited = visited?.find((visitedId) => visitedId === modalContent.id);
    const isWishlist = wishlist?.find((wishlistId) => wishlistId === modalContent.id);
    const [showEditRestaurant, setShowEditRestaurant] = useState(false);
    const [certificatePopupOpen, setCertificatePopupOpen] = useState(false);
    const [businessHoursOpen, setBusinessHoursOpen] = useState(false);
    const PriceLevel = {
        EXPENSIVE: 'PRICE_LEVEL_EXPENSIVE',
        FREE: 'PRICE_LEVEL_FREE',
        INEXPENSIVE: 'PRICE_LEVEL_INEXPENSIVE',
        MODERATE: 'PRICE_LEVEL_MODERATE',
        VERY_EXPENSIVE: 'PRICE_LEVEL_VERY_EXPENSIVE'
    };

    const priceLevelToDollarSigns = {
        [PriceLevel.FREE]: '$',
        [PriceLevel.INEXPENSIVE]: '$',
        [PriceLevel.MODERATE]: '$$',
        [PriceLevel.EXPENSIVE]: '$$$',
        [PriceLevel.VERY_EXPENSIVE]: '$$$$'
    };

    const dayToIndex = {
        'Sunday': 0,
        'Monday': 1,
        'Tuesday': 2,
        'Wednesday': 3,
        'Thursday': 4,
        'Friday': 5,
        'Saturday': 6
    }

    const handleClick = () => {
        setBusinessHoursOpen(!businessHoursOpen);
    };

    const handleClose = React.useCallback(() => {
        dispatch(setModalOpen(false));
        dispatch(setPreviewMode(false));
        dispatch(setModalRestaurant({}));
    }, [dispatch])

    useEffect(() => {
        if (open) {
            logEvent(getAnalytics(), `${modalContent?.name}: ${modalContent?.address}`, {page_title: 'Info Modal'});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    // Close Modal if back button is pressed
    useEffect(() => {
        const handleBackButton = (event) => {
            event.preventDefault();
            handleClose();
        };

        window.history.pushState({}, ''); // Push initial state to prevent closing on first back press
        window.addEventListener('popstate', handleBackButton);

        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, [handleClose]);


    useEffect(() => {
        window.history.replaceState('', '', '/restaurant/' + modalContent?.id);

        return () => {
            window.history.replaceState('', '', '/');
        }
    }, [modalContent?.id, open]);

    const handleFavorite = async () => {
        if (user === null) {
            setSignInOpen(true);
            return;
        }

        let restaurantId = modalContent.id;
        let userId = user.uid;

        const favoritesRef = doc(db, DB_PATHS.FAVORITES, userId);
        // Retrieve the current list of favorite restaurants

        // Add the new restaurant to the favorites array if it doesn't already exist
        if (!favorites.includes(restaurantId)) {
            let newFavorites = [...favorites];
            newFavorites.push(restaurantId);

            // Update the document in the "favorites" collection with the updated favorites array
            await setDoc(favoritesRef, {favorites: newFavorites});
            dispatch(addToFavorites(restaurantId));
        } else {
            let filteredFavs = favorites.filter(function (item) {
                return item !== restaurantId;
            });

            await setDoc(favoritesRef, {favorites: filteredFavs});
            dispatch(removeFromFavorites(restaurantId));
        }
    };

    const handleCloseAll = () => {
        setShowEditRestaurant(false);
        handleClose();
    }

    const handleWishlist = async () => {
        if (user === null) {
            setSignInOpen(true);
            return;
        }

        let restaurantId = modalContent.id;
        let userId = user.uid;

        const wishlistRef = doc(db, DB_PATHS.WISHLIST, userId);

        if (!wishlist.includes(restaurantId)) {
            let newWishlist = [...wishlist];
            newWishlist.push(restaurantId);

            // Update the document in the "visited" collection with the updated visited array
            await setDoc(wishlistRef, {wishlist: newWishlist});
            dispatch(addToWishlist(restaurantId));
        } else {
            let filteredWishlist = wishlist.filter(function (item) {
                return item !== restaurantId;
            });

            await setDoc(wishlistRef, {wishlist: filteredWishlist});
            dispatch(removeFromWishlist(restaurantId));
        }
    }

    const shareUrl = () => {
        const url = window.location.href;
        if (isMobileDevice()) {
            if (navigator.share) {
                navigator.share({
                    title: 'Check out this restaurant!',
                    url: url
                }).catch((error) => console.error('Error sharing', error));
            } else {
                navigator.clipboard.writeText(url).then(() => {
                    toast.success('URL copied to clipboard');
                }).catch((error) => console.error('Error copying URL', error));
            }
        } else {
            navigator.clipboard.writeText(url).then(() => {
                toast.success('URL copied to clipboard');
            }).catch((error) => console.error('Error copying URL', error));
        }
    };

    const handleVisited = async () => {
        if (user === null) {
            setSignInOpen(true);
            return;
        }

        let restaurantId = modalContent.id;
        let userId = user.uid;

        const visitedRef = doc(db, DB_PATHS.VISITED, userId);

        if (!visited.includes(restaurantId)) {
            let newVisited = [...visited];
            newVisited.push(restaurantId);

            // Update the document in the "visited" collection with the updated visited array
            await setDoc(visitedRef, {visited: newVisited});
            dispatch(addToVisited(restaurantId));
        } else {
            let filteredVisited = visited.filter(function (item) {
                return item !== restaurantId;
            });

            await setDoc(visitedRef, {visited: filteredVisited});
            dispatch(removeFromVisited(restaurantId));
        }
    };

    const openGoogleMaps = (googleMapsUri, address) => {
        if (googleMapsUri) {
            window.open(googleMapsUri);
            return;
        }

        const formattedAddress = encodeURIComponent(address);
        window.open(`https://www.google.com/maps/search/?api=1&query=${formattedAddress}`);
    };

    const [scrollPosition, setScrollPosition] = useState({ left: false, right: true });
    const containerRef = useRef(null);
    
    const handleScroll = () => {
        if (containerRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
            setScrollPosition({
                left: scrollLeft > 0,
                right: scrollLeft + clientWidth < scrollWidth,
            });
        }
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} fullScreen={fullScreen}>
                <DialogTitle>
                    <Grid container item alignItems='center'>
                        <Typography>
                            {modalContent?.name}
                        </Typography>
                        <IconButton onClick={shareUrl}>
                            <ShareIcon/>
                        </IconButton>
                    </Grid>
                    {previewMode && (
                        <Typography variant="subtitle1" style={{color: "gray"}}>
                            Preview Mode
                        </Typography>
                    )}
                    <IconButton
                        style={{position: "absolute", top: 10, right: 10}}
                        onClick={handleClose}
                    >
                        <CloseIcon/>
                    </IconButton>
                    {isAdmin && !previewMode && (
                        <Button style={{position: 'absolute', top: 15, right: 60}} variant='contained'
                                onClick={() => setShowEditRestaurant(true)}>
                            Edit
                        </Button>
                    )}
                </DialogTitle>
                <DialogContent>
                    <hr/>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            {modalContent?.businessStatus === 'CLOSED_PERMANENTLY' &&
                                <Chip label='Permanently Closed' color='error'/>}
                            {modalContent?.businessStatus === 'CLOSED_TEMPORARILY' &&
                                <Chip label='Temporarily Closed' color='error'/>}
                            {modalContent?.certificate && <Chip label="Halal Certification" color="primary"/>}
                            {isVisited && <Chip label="Visited" color="primary"/>}
                            {isFavorite && <Chip label="Favorite" color="error"/>}
                            {isWishlist && <Chip label="Interested" color="secondary"/>}
                            {modalContent?.menu_status === FULL_HALAL ? (
                                <Chip
                                    label={"Full Halal Menu"}
                                    style={{backgroundColor: "green", color: "white"}}
                                />
                            ) : modalContent?.menu_status === PARTIAL_HALAL ? (
                                <Chip
                                    label={"Partial Halal Menu"}
                                    style={{backgroundColor: "orange", color: "white"}}
                                />
                            ) : null}
                        </Grid>
                        <Grid item>
                            <Box
                                ref={containerRef}
                                onScroll={handleScroll}
                                sx={{
                                    display: 'flex',
                                    gap: 2,
                                    py: 1,
                                    width: '100%',
                                    maxWidth: '90vw',
                                    overflowX: 'auto',
                                    // Custom scrollbar hide for cleaner look
                                    '&::-webkit-scrollbar': {
                                        display: 'none',
                                    },
                                    // Fade effect for scrollable container edges
                                    maskImage: scrollPosition.left && scrollPosition.right
                                        ? 'linear-gradient(to right, transparent, black 40px, black calc(100% - 40px), transparent)'
                                        : scrollPosition.right
                                            ? 'linear-gradient(to right, black 40px, black calc(100% - 40px), transparent)'
                                            : scrollPosition.left
                                                ? 'linear-gradient(to right, transparent, black 40px, black)'
                                                : 'none',
                                }}
                            >
                                {modalContent?.address && (
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        sx={{
                                            flexShrink: 0,
                                            textTransform: 'none',
                                        }}
                                        startIcon={<DirectionsIcon />} // Add the icon here
                                        onClick={() => openGoogleMaps(modalContent?.googleMapsUri, modalContent?.address)}
                                    >
                                        Directions
                                    </Button>
                                )}
                                {modalContent?.certificate && (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        sx={{
                                            flexShrink: 0,
                                            textTransform: 'none',
                                        }}
                                        startIcon={<AutoStoriesIcon />} // Add the icon for Show Certificate button
                                        onClick={() => setCertificatePopupOpen(true)}
                                    >
                                        Show Certificate
                                    </Button>
                                )}
                                {modalContent?.website && (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        sx={{
                                            flexShrink: 0,
                                            textTransform: 'none',
                                        }}
                                        startIcon={<LanguageIcon />} // Add the icon for Website button
                                        onClick={() => window.open(modalContent?.website, '_blank')} // Opens the website in a new tab
                                    >
                                        Website
                                    </Button>
                                )}
                            </Box>
                        </Grid>
                        <Grid item>
                            <UserIcons
                                onClickVisited={handleVisited}
                                visited={isVisited}
                                onClickFavorite={handleFavorite}
                                favorite={isFavorite}
                                onClickInterested={handleWishlist}
                                interested={isWishlist}
                            />
                        </Grid>
                        {modalContent?.description && (
                            <Grid item>
                                <Typography variant="subtitle1">
                                    <strong>Description</strong>
                                </Typography>
                                <Divider sx={{borderBottom: "2px solid black"}}/>
                                <Typography>{modalContent?.description}</Typography>
                            </Grid>
                        )}
                        {modalContent?.tags && (
                            <Grid item>
                                {modalContent?.tags.map((tag, index) => (
                                    <Chip key={index} label={tag} style={{marginRight: "5px"}}/>
                                ))}
                            </Grid>
                        )}
                        <List>
                            {modalContent?.address && (
                                <ListItemButton onClick={() => openGoogleMaps(modalContent?.googleMapsUri, modalContent?.address)}>
                                    <ListItemIcon>
                                        <LocationOnIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={`${modalContent?.address}`}/>
                                </ListItemButton>
                            )}
                            {modalContent?.currentOpeningHours && (
                                <List component="nav">
                                    <ListItemButton onClick={handleClick}>
                                        <ListItemIcon>
                                            <AccessTimeIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="Business Hours"/>
                                        {businessHoursOpen ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                                    </ListItemButton>
                                    <Collapse in={businessHoursOpen} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {modalContent?.currentOpeningHours.map((day, index) => {
                                                const dayIndex = dayToIndex[day.split(':')[0]];
                                                const today = new Date().getDay();
                                                if (dayIndex === today) {
                                                    return (
                                                        <ListItemButton key={index}
                                                                        sx={{pl: 4, backgroundColor: '#f0f0f0'}}>
                                                            <ListItemText
                                                                primaryTypographyProps={{style: {fontWeight: 900}}}
                                                                primary={day}/>
                                                        </ListItemButton>
                                                    )
                                                }

                                                return (
                                                    <ListItemButton key={index} sx={{pl: 4}}>
                                                        <ListItemText primary={day}/>
                                                    </ListItemButton>
                                                )
                                            })}
                                        </List>
                                    </Collapse>
                                </List>
                            )}
                            {modalContent?.phoneNumber && (
                                <ListItemButton component="a" href={`tel:${modalContent?.phoneNumber}`}>
                                    <ListItemIcon>
                                        <Phone/>
                                    </ListItemIcon>
                                    <ListItemText primary={`${modalContent?.phoneNumber}`}/>
                                </ListItemButton>
                            )}
                            {modalContent?.priceLevel && (
                                <ListItemButton>
                                    <ListItemIcon>
                                        <AttachMoney/>
                                    </ListItemIcon>
                                    <ListItemText primary={`${priceLevelToDollarSigns[modalContent?.priceLevel]}`}/>
                                </ListItemButton>
                            )}
                            {modalContent?.rating && (
                                <ListItemButton>
                                    <ListItemIcon>
                                        <Star/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={`${modalContent?.rating} ${modalContent?.userRatingCount ? `(${modalContent.userRatingCount} reviews)` : ''}`}/>
                                </ListItemButton>
                            )}
                        </List>
                        {modalContent?.placeId && isAdmin && (
                            <Grid item>
                                <PlacesApiData placeId={modalContent.placeId}/>
                            </Grid>
                        )}
                        <Grid item>
                            {modalContent && modalContent.reels && modalContent.reels.length > 0 && modalContent.reels[0] !== '' ? (
                                // Render Instagram posts if reels array is not empty
                                modalContent.reels.map((post, index) => (
                                    <InstagramPostEmbed key={index} postUrl={post}/>
                                ))
                            ) : (
                                !modalContent?.instagramProfileTag && (
                                    <ReelsPlaceholder restaurant={modalContent}/>
                                )
                            )}
                        </Grid>
                        {modalContent?.instagramProfileTag && (
                            <Grid item>
                                <InstagramProfile profileTag={modalContent.instagramProfileTag}/>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
            </Dialog>
            <SignIn
                open={signInOpen}
                handleClose={() => setSignInOpen(false)}
            />
            {showEditRestaurant && (
                <EditRestaurant
                    open={showEditRestaurant}
                    handleClose={() => setShowEditRestaurant(false)}
                    restaurant={modalContent}
                    handleCloseAll={handleCloseAll}
                />
            )}
            {certificatePopupOpen && (
                <CertificatePopup
                    open={certificatePopupOpen}
                    handleClose={() => setCertificatePopupOpen(false)}
                    certificateUrl={modalContent.certificate}
                />
            )}
        </>

    );
}
